import React, {lazy, useCallback, useState} from 'react'

// Creates a lazy loader with a minimum load time of 300 seconds to allow the suspense fallback to show long enough to make sense
const minimumDelayLazyLoader = (filename) =>
  lazy(() =>
    Promise.all([
      import(`./Tabs/${filename}.jsx`),
      new Promise((resolve) => {
        setTimeout(resolve, 300)
      }),
    ]).then(([moduleExports]) => moduleExports),
  )

const GeneralTab = minimumDelayLazyLoader('GeneralTab')
const AccountingTab = minimumDelayLazyLoader('AccountingTab')
const BankingTab = minimumDelayLazyLoader('BankingTab')
const PasswordTab = minimumDelayLazyLoader('PasswordTab')

const tabs = [
  {tabName: 'general', component: <GeneralTab />},
  {tabName: 'accounting', component: <AccountingTab />},
  {tabName: 'banking', component: <BankingTab />},
  {tabName: 'password', component: <PasswordTab />},
]

export default function useTabs({settingsUrls}) {
  const currentUrlTabName = Object.entries(settingsUrls).find(
    (entry) => entry[1] === window.location.toString(),
  )[0]
  const currentTabIndex = tabs.findIndex(
    (tab) => tab.tabName === currentUrlTabName,
  )
  const [tabIndex, setTabIndex] = useState(
    currentTabIndex === -1 ? 0 : currentTabIndex,
  )

  const currentTabObject = tabs[tabIndex] || tabs[0]

  const changeTabs = useCallback(
    (index) => {
      setTabIndex(index)

      window.history.replaceState(
        {},
        '',
        new URL(settingsUrls[tabs[index].tabName]),
      )
    },
    [settingsUrls],
  )

  return {
    tabs,
    tabIndex,
    changeTabs,
    currentTabObject,
  }
}
