import PropTypes from 'prop-types'
import React from 'react'
import {Box, Flex, Link, Text} from '@chakra-ui/react'
import {InvoicesIcon} from '../../icons'
import {formatDateString} from '../../../utils/formatter'
import {Alert} from '../../components'

const STATEMENTS_FEEDBACK_URL =
  'https://docs.google.com/forms/d/e/1FAIpQLScmhHb14O_mS12bEfjr8ahO8i3Uvw2Dtc6-_XqoXAJhCedCOw/viewform'

export const Statements = ({latestStatement}) => {
  return (
    <Flex
      direction="column"
      flexGrow="1"
      whiteSpace="nowrap"
      mt="md"
      px="md"
      py="sm"
      gap="xs"
      borderRadius="lg"
      backgroundColor="neutral.smoke"
    >
      <Flex align="center" justifyContent="space-between">
        <Text fontSize="md" fontWeight="medium" color="neutral.80">
          Statements
        </Text>
        <Link
          href={latestStatement.statementPath}
          target="_blank"
          display="flex"
          flexDirection="row"
          alignItems="center"
          color={
            latestStatement.statementPath ? 'primary.actionblue' : 'neutral.40'
          }
        >
          <InvoicesIcon mr="xs" />
          <Text fontWeight="medium" textTransform="uppercase">
            View Live Statement
          </Text>
        </Link>
      </Flex>
      <Flex align="center">
        <Box width="200px">
          <Text color="neutral.80">Frequency:</Text>
        </Box>
        <Box width="calc(100% - 200px)">
          <Text fontWeight="medium">{latestStatement.frequency}</Text>
        </Box>
      </Flex>
      <Flex align="center">
        <Box width="200px">
          <Text color="neutral.80">Last Sent:</Text>
        </Box>
        <Box width="calc(100% - 200px)">
          <Text fontWeight="medium">
            {latestStatement.sentAt
              ? formatDateString(latestStatement.sentAt)
              : 'N/A'}
          </Text>
        </Box>
      </Flex>
      <Alert
        status="info"
        message={
          <>
            Your cashed-in invoices show as{' '}
            <Text fontWeight="medium" as="span">
              &apos;unpaid&apos;
            </Text>{' '}
            on the statement.
          </>
        }
      />
      <Link
        href={STATEMENTS_FEEDBACK_URL}
        color="primary.actionblue"
        rel="nofollow"
        target="_blank"
      >
        Give us feedback on the statements
      </Link>
    </Flex>
  )
}

Statements.propTypes = {
  latestStatement: PropTypes.shape({
    frequency: PropTypes.string.isRequired,
    sentAt: PropTypes.string,
    statementPath: PropTypes.string,
  }).isRequired,
}

export default Statements
