export default {
  fonts: {
    heading: 'AktivGrotesk, serif',
    body: 'AktivGrotesk, sans-serif',
  },
  fontSizes: {
    xs: '12px',
    sm: '14px',
    base: '16px',
    md: '18px',
    lg: '20px',
    xl: '24px',
    '2xl': '36px',
    '3xl': '100px',
  },
  lineHeights: {
    0: '90%',
    1: '1',
    2: '110%',
    3: '150%',
    4: '125%',
    5: 'auto',
  },
  letterSpacings: {
    0: '-0.06rem',
    1: '-0.05rem',
    2: '-0.04rem',
    3: '-0.01rem',
    4: '-0.02rem',
    5: 'normal',
  },
  textStyles: {
    headline1: {
      fontFamily: 'heading',
      fontSize: '3xl',
      lineHeight: 0,
      fontWeight: 'normal',
      letterSpacing: 0,
    },
    headline2: {
      fontFamily: 'heading',
      fontSize: '2xl',
      lineHeight: 0,
      fontWeight: 'normal',
      letterSpacing: 1,
    },
    headline3: {
      fontFamily: 'body',
      fontSize: 'xl',
      lineHeight: 1,
      fontWeight: 'normal',
      letterSpacing: 2,
    },
    headline4: {
      fontFamily: 'body',
      fontSize: 'lg',
      lineHeight: 1,
      fontWeight: 'normal',
      letterSpacing: 3,
    },
    headline5: {
      fontFamily: 'body',
      fontSize: 'sm',
      lineHeight: 2,
      fontWeight: 'normal',
      letterSpacing: 3,
    },
    'headline5-medium': {
      fontFamily: 'body',
      fontSize: 'sm',
      lineHeight: 2,
      fontWeight: 'medium',
      letterSpacing: 3,
    },
    'body-intro': {
      fontFamily: 'body',
      fontSize: 'md',
      lineHeight: 3,
      fontWeight: 'normal',
      letterSpacing: 4,
    },
    'body-intro-medium': {
      fontFamily: 'body',
      fontSize: 'md',
      lineHeight: 3,
      fontWeight: 'medium',
      letterSpacing: 4,
    },
    'body-copy': {
      fontFamily: 'body',
      fontSize: 'base',
      lineHeight: 3,
      fontWeight: 'normal',
      letterSpacing: 4,
    },
    'body-copy-medium': {
      fontFamily: 'body',
      fontSize: 'base',
      lineHeight: 3,
      fontWeight: 'medium',
      letterSpacing: 4,
    },
    'body-small': {
      fontFamily: 'body',
      fontSize: 'sm',
      lineHeight: 3,
      fontWeight: 'normal',
      letterSpacing: 4,
    },
    'body-small-medium': {
      fontFamily: 'body',
      fontSize: 'sm',
      lineHeight: 3,
      fontWeight: 'medium',
      letterSpacing: 4,
    },
    'body-detail': {
      fontFamily: 'body',
      fontSize: 'xs',
      lineHeight: 4,
      fontWeight: 'normal',
      letterSpacing: 5,
    },
    'body-detail-medium': {
      fontFamily: 'body',
      fontSize: 'xs',
      lineHeight: 4,
      fontWeight: 'medium',
      letterSpacing: 5,
    },
    caption: {
      fontFamily: 'body',
      fontSize: 'xs',
      lineHeight: 4,
      fontWeight: 'normal',
      letterSpacing: 5,
      textTransform: 'uppercase',
    },
    'caption-medium': {
      fontFamily: 'body',
      fontSize: 'xs',
      lineHeight: 4,
      fontWeight: 'medium',
      letterSpacing: 5,
      textTransform: 'uppercase',
    },
    button: {
      fontFamily: 'body',
      fontSize: 'sm',
      lineHeight: 5,
      fontWeight: 'medium',
      letterSpacing: 3,
      textTransform: 'uppercase',
    },
    tag: {
      fontFamily: 'body',
      fontSize: 'xs',
      lineHeight: '12px',
      fontWeight: 'normal',
      textTransform: 'uppercase',
    },
  },
}
