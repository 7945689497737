import React from 'react'
import {
  Box,
  CloseButton,
  Text,
  Divider,
  Flex,
  List,
  ListItem,
} from '@chakra-ui/react'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'

import {useRails} from '../../../../contexts/rails'
import {LogoHorizontalSecondary} from '../../../../components/Logo'
import DropdownSelect from '../../../../components/DropdownSelect'
import {InverseSolidTickedIcon} from '../../../../icons/InverseSolidTickedIcon'

const SideNav = ({dropdownSelectProps, onClose}) => {
  const {t} = useTranslation('onboarding')
  const {bankAccountStepEnabled, xero, compliance} = useRails()
  const steps = ['loginCreated', 'accountingConnection']
  if (bankAccountStepEnabled) {
    steps.push('bankConnection')
  }
  const currentStep =
    xero.isExpenseAccountSelected && compliance.isQuestionnaireAnswered
      ? 'bankConnection'
      : 'accountingConnection'

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      alignContent="stretch"
      bg="mld.sideNav.background"
      width="inherit"
      height="100%"
      position="fixed"
      top="0"
      left="0"
      zIndex="9"
    >
      <Flex my="34px" flexGrow={0} width="100%" px="40px">
        <Text textStyle="headline3" color="neutral.50" my="auto">
          {t('onboarding:sideNav.heading')}
        </Text>
        <CloseButton
          display={{base: 'block', lg: 'none'}}
          onClick={onClose}
          width="24px"
          ml="auto"
          color="white"
        />
      </Flex>
      <Divider mb="sm" borderColor="mld.sideNav.dividerColor" opacity="0.2" />
      {dropdownSelectProps && dropdownSelectProps.options.length > 1 && (
        <>
          <Box px="26px" mb="sm" maxWidth="386px" width="100%">
            <DropdownSelect
              name={dropdownSelectProps.name}
              placeholder={dropdownSelectProps.placeholder}
              options={dropdownSelectProps.options}
              value={dropdownSelectProps.value}
              isClearable={false}
              isSearchable={false}
              variant="onboardingSidenav"
              onChange={dropdownSelectProps.onChange}
            />
          </Box>
          <Divider
            mb="sm"
            borderColor="mld.sideNav.dividerColor"
            opacity="0.2"
          />
        </>
      )}
      <Box flexGrow={0} width="100%" mb="sm">
        <List>
          {steps.map((step, index) => {
            const currentStepIndex = steps.indexOf(currentStep)
            const previousStep = index < currentStepIndex
            const onCurrentStep = index === currentStepIndex

            return (
              <ListItem
                key={step}
                color="mld.neutral.500"
                py="xs"
                mx="40px"
                mt="md"
                mb="lg"
                borderBottom={`1px solid ${
                  !onCurrentStep ? '#FFFFFF66' : '#FFFFFF'
                }`}
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
              >
                <Flex my="xs" justifyContent="space-between" width="full">
                  <Flex>
                    <Text
                      textStyle="headline4"
                      casing="uppercase"
                      color={!onCurrentStep ? '#FFFFFF66' : 'mld.neutral.50'}
                    >
                      {index + 1}.{' '}
                    </Text>
                    <Text
                      as="span"
                      textStyle="headline4"
                      casing="uppercase"
                      color={!onCurrentStep ? '#FFFFFF66' : 'mld.neutral.50'}
                      ml="xs"
                    >
                      {t(`onboarding:sideNav.steps.${step}`)}
                    </Text>
                  </Flex>
                  {previousStep && (
                    <InverseSolidTickedIcon
                      ml="auto"
                      boxSize="24px"
                      color="mld.neutral.50"
                    />
                  )}
                </Flex>
              </ListItem>
            )
          })}
        </List>
      </Box>
      <Divider mt="auto" borderColor="mld.sideNav.dividerColor" opacity="0.2" />
      <Box mx="auto" my="40px" height="24px">
        <LogoHorizontalSecondary width="203px" height="24px" />
      </Box>
    </Box>
  )
}

const dropdownOptionShape = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
})

SideNav.propTypes = {
  dropdownSelectProps: PropTypes.shape({
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(dropdownOptionShape).isRequired,
    value: dropdownOptionShape.isRequired,
    onChange: PropTypes.func.isRequired,
  }),
  onClose: PropTypes.func,
}

SideNav.defaultProps = {
  dropdownSelectProps: undefined,
  onClose: () => {},
}

export default SideNav
